/******MainBody Tags Starts******/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0 !important;
  font-size: 16px !important;
  color: #f5f5ff !important;
  padding: 0 !important;
  background-color: #2e3239 !important;
}
a {
  cursor: pointer !important;
  text-decoration: none !important;
  color: #f5f5ff !important;
}
.App--Main {
  display: inline-block;
  width: 100%;
}
.Body--Main {
  padding: 44px 0 0 60px;
}
.no-display {
  display: none !important;
}
.mt--5 {
  margin: 5px 0 0 0;
}
.mt--10 {
  margin: 10px 0 0 0;
}
.mt--15 {
  margin: 15px 0 0 0;
}
.mt--20 {
  margin: 20px 0 0 0;
}
.mt--25 {
  margin: 25px 0 0 0;
}
.mt--30 {
  margin: 30px 0 0 0;
}
.mt--35 {
  margin: 35px 0 0 0;
}
.mt--40 {
  margin: 40px 0 0 0;
}
.mt--45 {
  margin: 45px 0 0 0;
}
.mt--50 {
  margin: 50px 0 0 0;
}
/******MainBody Tags Starts******/

/**Header Starts**/
.header {
  background: #292b2f;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 0.3px solid #000;
  z-index: 100;
}
.header-start__logo {
  margin: 0;
  display: flex;
}
.user--img {
  display: flex;
  width: 30px;
  height: 30px;
  background: #e50d4b;
  border-radius: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.user--img img {
  display: flex;
  max-width: 30px;
  max-height: 30px;
  border-radius: 100%;
}
.user--img span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
}
/**Header Ends**/

/**Left Menu starts**/
.menu {
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 99;
  background: #292b2f;
  height: 100%;
  width: 60px;
  top: 44px;
  padding: 5px 0 0 0;
}
.menu--link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu--link a {
  display: flex;
  width: 35px;
  height: 35px;
  margin: 25px 0 0 0;
  justify-content: center;
  align-items: center;
  background: #e50d4b;
  border-radius: 100%;
}
.menu--link:nth-child(2) a {
  background: #2152a0;
}
.menu--link:nth-child(3) a {
  background: #f8b516;
}
/**Left Menu Ends**/

/**App Dropdowns Starts**/
.app--drops {
  position: absolute;
  left: 0;
  background: #222326;
  border: 0.2px solid #ccc;
  width: 190px;
  z-index: 100;
  padding: 10px 0;
}
.app--drops__item {
  display: flex;
  font-size: 12px;
  align-items: center;
  height: 30px;
  padding: 0 12px;
}
.app--drops__item img {
  margin: 0 10px 0 0;
}
.app--drops__item:hover {
  background-color: #292b2f;
}
/**App Dropdowns Ends**/

/**Radio Starts**/
.radio--btn {
  position: relative;
}
.radio--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0;
  z-index: 100;
}
.radio--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: none;
  border-radius: 50%;
  border: 2px solid #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.radio--btn:hover input ~ .radio--mark {
  background-color: none;
}
.radio--btn input:checked ~ .radio--mark {
  background-color: none;
}
.radio--mark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio--btn input:checked ~ .radio--mark:after {
  display: block;
}
.radio--btn .radio--mark:after {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #9a9a9b;
}
/**Radio Ends**/

/**Checkbox Starts**/
.check--btn {
  position: relative;
}
.check--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0;
  z-index: 100;
}
.check--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: none;
  border-radius: 5px;
  border: 2px solid #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check--btn:hover input ~ .check--mark {
  background-color: none;
}
.check--btn input:checked ~ .check--mark {
  background-color: none;
}
.check--mark:after {
  content: "";
  position: absolute;
  display: none;
}
.check--btn input:checked ~ .check--mark:after {
  display: block;
}
.check--btn .check--mark:after {
  width: 8px;
  height: 14px;
  border: solid #9a9a9b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 4px;
}
/**Checkbox Ends**/

/**Scroll Starts**/
.custom--scroll {
  scrollbar-color: #292b2f Transparent;
  scrollbar-width: thin;
}
.custom--scroll::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}
.custom--scroll::-webkit-scrollbar-thumb {
  background: #292b2f;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-thumb:hover {
  background: #292b2f;
}
/**Scroll Ends**/

/**Admin Control Starts**/
.admin-form {
  padding: 30px;
}
.admin-search {
  border: 1px solid #9a9a9b;
  border-radius: 5px;
}
.admin-search h3 {
  background: #292b2f;
  border-radius: 5px 5px 0px 0px;
  font-size: 20px;
  padding: 12px;
}
.admin-search form {
  padding: 20px;
}
.admin-search form span {
  color: #9a9a9b;
  margin: 0 0 3px 0;
  display: flex;
  font-size: 14px;
}
.admin-search form input {
  background: transparent;
  border: 1px solid #9a9a9b;
  color: #9a9a9b !important;
  outline: none !important;
  height: 40px;
}
.admin-search form input:focus {
  background: transparent !important;
  outline: none !important;
}
.admin-search form select {
  background: transparent;
  border: 1px solid #9a9a9b;
  color: #9a9a9b !important;
  outline: none !important;
  height: 40px;
  background-image: url(/public/images/plus.png);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: calc(100% - 15px) center;
}
.admin-search form select option {
  background: #292b2f !important;
}
.admin-search form select:focus {
  background-color: transparent !important;
  outline: none !important;
}
.admin-search form textarea {
  background: transparent;
  border: 1px solid #9a9a9b;
  color: #9a9a9b !important;
  outline: none !important;
  height: 40px;
}
.admin-search form textarea:focus {
  background: transparent !important;
  outline: none !important;
}
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0 0 0 !important;
}
.admin-add {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 10px 0;
  position: relative;
}
.admin-table table th {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  min-width: 150px;
}
.admin-table table td {
  font-size: 12px;
  font-weight: 400;
}
.admin-table table td button {
  margin: 0 5px;
  width: 35px;
  height: 35px;
  padding: 0 0 3px 0;
}
.admin-form .col-sm-4 {
  margin: 0 0 30px 0;
}
.admin-add button {
  display: flex;
  padding: 0;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 15px;
}
.admin-show-hide {
  position: absolute;
  background: #212529;
  right: 0;
  top: 38px;
  border-radius: 0.25rem;
}
.admin-show-hide {
  position: absolute;
  background: #212529;
  right: 0;
  top: 38px;
  border-radius: 0.25rem;
  max-height: 500px;
  overflow: auto;
}
.admin-show-hide ul {
  padding: 15px;
  list-style-type: none;
}
.admin-show-hide ul li {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 40px;
}
.admin-show-hide ul li .input--radio.check--btn {
  padding: 0 0 0 50px;
  display: inline-block;
  height: 30px;
}
.admin-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  margin-bottom: 50px;
}
.admin-pagination .pagination {
  margin: 0;
}
.admin-pagination select {
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  padding: 3px 5px;
  background: #212529;
  margin: 0 0 0 15px;
  border: 1px solid #414549;
}
.admin-pagination .page-item .page-link {
  background: #212529;
  border-color: #414549;
}
.admin-pagination .page-item.active .page-link {
  background: #0b5ed7;
}
.admin-pagination .page-item.disabled {
  opacity: 0.5;
}
.rotate-180 {
  transform: rotate(180deg);
}
.admin--sort {
  float: right;
  width: 10px;
  margin: 3px 5px 0 0;
}
.admin--sort img {
  width: 10px;
  height: 5px;
  display: flex;
}
.admin--sort img.rotate-180 {
  margin: 0 0 4px 0;
}
/**Admin Control Ends**/
/**Loader Starts**/
.loader-overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
}
/**Loader Ends**/
